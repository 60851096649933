import { EmdiResponse, EmdiClasses, EmdiResponses } from '../emdi-defs';

export class EgmIdResponse implements EmdiResponse {
  name = 'EgmId';
  responseType = EmdiResponses.EgmId;
  class = EmdiClasses.Host;
  sessionId: number;
  xml: string;
  egmId: string;

  constructor(data: string) {
    // console.log('EgmIdResponse: data =', data);

    this.egmId = data['$']['hst:egmId'];
  }
}
