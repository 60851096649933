export interface MeterDefinition {
  name: string;
  type: MeterType;
  text: string;
}

export interface EventDefinition {
  code: string;
  text: string;
}

export class EmdiConstants {
  static testerDeviceId = 4;

  static all = 'IGT_all';

  static meterDefs: MeterDefinition[] = [
    {
      name: 'IGT_playerPointBalance',
      type: 'IGT_count',
      text: 'Total Player Point Balance'
    },
    {
      name: 'IGT_playerPointCountdown',
      type: 'IGT_count',
      text: 'Player Point Countdown'
    },
    {
      name: 'IGT_playerSessionPoints',
      type: 'IGT_count',
      text: 'Player Session Points'
    },
    {
      name: 'IGT_wagerMatchBalance',
      type: 'IGT_amount',
      text: 'Total Wager Match Balance'
    },
    {
      name: 'G2S_playerCashableAmt',
      type: 'IGT_amount',
      text: 'Player Cashable Amount'
    },
    {
      name: 'G2S_playerPromoAmt',
      type: 'IGT_amount',
      text: 'Player Promotional Amount'
    },
    {
      name: 'G2S_playerNonCashAmt',
      type: 'IGT_amount',
      text: 'Player Non-Cashable Amount'
    }
  ];

  static eventDefs = [
    { code: 'IGT_GME101', text: 'Game Idle' },
    { code: 'IGT_GME102', text: 'Primary Game Escrow' },
    { code: 'IGT_GME103', text: 'Primary Game Started' },
    { code: 'IGT_GME104', text: 'Primary Game Ended' },
    { code: 'IGT_GME105', text: 'Progressive Pending' },
    { code: 'IGT_GME106', text: 'Secondary Game Choice' },
    { code: 'IGT_GME107', text: 'Secondary Game Escrow' },
    { code: 'IGT_GME108', text: 'Secondary Game Started' },
    { code: 'IGT_GME109', text: 'Secondary Game Ended' },
    { code: 'IGT_GME110', text: 'Pay Game Results' },
    { code: 'IGT_GME111', text: 'Game Ended' },
    { code: 'G2S_GME101', text: 'Game Idle (RCL)' },
    { code: 'G2S_GME102', text: 'Primary Game Escrow (RCL)' },
    { code: 'G2S_GME103', text: 'Primary Game Started (RCL)' },
    { code: 'G2S_GME104', text: 'Primary Game Ended (RCL)' },
    { code: 'G2S_GME105', text: 'Progressive Pending (RCL)' },
    { code: 'G2S_GME106', text: 'Secondary Game Choice (RCL)' },
    { code: 'G2S_GME107', text: 'Secondary Game Escrow (RCL)' },
    { code: 'G2S_GME108', text: 'Secondary Game Started (RCL)' },
    { code: 'G2S_GME109', text: 'Secondary Game Ended (RCL)' },
    { code: 'G2S_GME110', text: 'Pay Game Results (RCL)' },
    { code: 'G2S_GME111', text: 'Game Ended (RCL)' },
    { code: 'IGT_BTN100', text: 'Change Button Pressed' },
    { code: 'IGT_IDE100', text: 'Card Inserted' },
    { code: 'IGT_IDE101', text: 'Card Removed' },
    { code: 'IGT_IDE102', text: 'Card Abandoned' },
    { code: 'IGT_CBE100', text: 'Cabinet Status' },
    { code: 'G2S_MDE100', text: 'Display Interface Open' },
    { code: 'G2S_MDE101', text: 'Display Interface Closed' },
    { code: 'G2S_MDE001', text: 'Display Device Shown' },
    { code: 'G2S_MDE002', text: 'Display Device Hidden' }
  ];
}

export enum EmdiClasses {
  Comms = 'Comms',
  EventHandler = 'EventHandler',
  Cabinet = 'Cabinet',
  Meters = 'Meters',
  ContentToContent = 'ContentToContent',
  Host = 'Host',
  None = 'None'
}

export enum EmdiEvents {
  MeterReport,
  EventReport,
  ContentMessage,
  HostToContentMessage
}

export enum EmdiResponses {
  HeartbeatAck,
  CommsOnLineAck,
  FunctionalGroupList,
  EventSubList,
  MeterSubList,
  ClearEventSubAck,
  ContentMessageAck,
  ContentToHostMessageAck,
  ActiveContentList,
  CabinetStatus,
  CallAttendantStatus,
  CardStatus,
  DeviceVisibleStatus,
  SupportedEventList,
  SupportedMeterList,
  LogContentEventAck,
  MeterReport,
  EgmId
}

export enum EmdiRequests {
  ClearEventSub,
  ClearMeterSub,
  CommsOnLine,
  ContentMessage,
  ContentToHostMessage,
  GetActiveContent,
  GetCardState,
  GetCabinetStatus,
  GetCallAttendantState,
  GetDeviceVisibleState,
  GetEventSubList,
  GetFunctionalGroups,
  GetEgmId,
  GetMeterSub,
  GetMeterInfo,
  GetSupportedMeterList,
  GetSupportedEventList,
  Heartbeat,
  LogContentEvent,
  SetCallAttendantState,
  SetCardRemoved,
  SetDeviceVisibleState,
  SetEventSub,
  SetMeterSub
}

export enum EmdiAcks {
  ContentMessageAck,
  EventAck,
  HostToContentMessageAck,
  MeterReportAck
}

export declare type MeterType = 'IGT_count' | 'IGT_amount' | 'IGT_percent';

export interface MeterSubscription {
  name: string;
  type: MeterType;
}

export interface EventSubscription {
  code: string;
}

export interface EmdiCommand {
  name: string;
  class: EmdiClasses;
  sessionId: number;
  xml(): string;
}

export interface EmdiRequest extends EmdiCommand {
  requestType: EmdiRequests;
}

export interface EmdiAck extends EmdiCommand {
  ackType: EmdiAcks;
}

export {};

export interface EmdiResponse {
  name: string;
  responseType: EmdiResponses;
  class: EmdiClasses;
  sessionId: number;
  xml: string;
}

export interface EmdiEvent {
  name: string;
  eventType: EmdiEvents;
  class: EmdiClasses;
  ack: EmdiCommand;
  sessionId: number;
  xml: string;
}

export class EmdiError {
  constructor(error: any, cls: EmdiClasses) {
    this.error = error;
    this.class = cls;
  }

  error: any;
  class: EmdiClasses;
}

export enum EgmStates {
  TransportDisabled = 'G2S_transportDisabled',
  OperatorDisabled = 'G2S_operatorDisabled',
  HostDisabled = 'G2S_hostDisabled',
  EgmDisabled = 'G2S_egmDisabled',
  Enabled = 'G2S_enabled',
  OperatorMode = 'G2S_operatorMode',
  DemoMode = 'G2S_demoMode',
  AuditMode = 'G2S_auditMode',
  OperatorLocked = 'G2S_operatorLocked',
  EgmLocked = 'G2S_egmLocked',
  HostLocked = 'G2S_hostLocked'
}

export enum PlayResults {
  NoResult = 'G2S_noResult',
  GameFailed = 'G2S_gameFailed',
  GameLost = 'G2S_gameLost',
  GameTied = 'G2S_gameTied',
  GameWon = 'G2S_gameWon'
}

export interface EventReportItem {
  code: string;
}

export interface EgmStateChangedEventItem extends EventReportItem {
  egmState: string;
}

export interface LocaleChangedEventItem extends EventReportItem {
  localeId: string;
}

export interface CardInsertedEventItem extends EventReportItem {
  idNumber: string;
}

export interface CardRemovedEventItem extends EventReportItem {
}

export interface MeterReportItem {
  name: string;
  type: MeterType;
  value: number;
}

export interface SupportedEvent {
  code: string;
  text: string;
}

export interface SupportedMeter {
  name: string;
  type: MeterType;
}

export interface ActiveContent {
  displayId: number;
  contentId: number;
}
