import { EmdiClasses, EmdiRequests, EmdiRequest } from '../emdi-defs';

export class ContentToHostMessageRequest implements EmdiRequest {
  name = 'ContentToHostMessage';
  requestType = EmdiRequests.ContentToHostMessage;
  class = EmdiClasses.Cabinet;
  sessionId = 0;
  instructionData: string;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com" xmlns:hci="http://www.gamingstandards.com/emdi/schemas/v1b/HCI">
            <md:mdCabinet md:cmdType="request" md:sessionId="${this.sessionId}">
                <hci:contentToHostMessage>
                    <hci:instructionData>${btoa(
                      this.instructionData
                    )}</hci:instructionData>
                </hci:contentToHostMessage>
            </md:mdCabinet>
        </md:mdMsg>`;
  }
}
