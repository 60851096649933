import { EmdiRequests, EmdiClasses, EmdiRequest } from '../emdi-defs';

export class LogContentEventRequest implements EmdiRequest {
  name = 'LogContentEvent';
  requestType = EmdiRequests.LogContentEvent;
  class = EmdiClasses.EventHandler;
  sessionId = 0;
  contentName: string;
  eventName: string;
  eventDescription: string;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdEventHandler xmlns:md="http://mediaDisplay.igt.com" md:cmdType="request" md:sessionId="${this.sessionId}">
               <md:logContentEvent md:contentName="${this.contentName}" md:eventName="${this.eventName}" md:eventDescription="${this.eventDescription}" />
           </md:mdEventHandler>
        </md:mdMsg>`;
  }
}
