import {
  EmdiCommand,
  EmdiResponse,
  EmdiEvent,
  EmdiRequests,
  EmdiResponses,
  EmdiEvents,
  EmdiError
} from './emdi-defs';
import {
  HeartbeatRequest,
  HeartbeatAckResponse,
  CommsOnLineRequest,
  CommsOnLineAckResponse,
  GetFunctionalGroupsRequest,
  FunctionalGroupListResponse,
  EventReportEvent,
  MeterReportEvent,
  HostToContentMessageEvent,
  ClearEventSubRequest,
  ClearMeterSubRequest,
  ContentMessageRequest,
  ContentToHostMessageRequest,
  GetActiveContentRequest,
  GetCabinetStatusRequest,
  GetCallAttendantStateRequest,
  GetCardStateRequest,
  GetDeviceVisibleStateRequest,
  GetEventSubListRequest,
  GetMeterInfoRequest,
  GetMeterSubCommand,
  GetSupportedEventListRequest,
  GetSupportedMeterListRequest,
  LogContentEventRequest,
  SetCallAttendantStateRequest,
  SetCardRemovedRequest,
  SetDeviceVisibleStateRequest,
  SetEventSubRequest,
  SetMeterSubRequest,
  ContentMessageEvent,
  EventSubListResponse,
  ClearEventSubAckResponse,
  MeterSubListResponse,
  ContentMessageAckResponse,
  ContentToHostMessageAckResponse,
  CabinetStatusResponse,
  ActiveContentListResponse,
  LogContentEventAckResponse,
  MeterReportResponse,
  GetEgmIdRequest,
  EgmIdResponse
} from './commands';
import * as xml2js from 'browser-xml2js';
import { DeviceVisibleStatusResponse } from './commands/device-visible-status';
import { CallAttendantStatusResponse } from './commands/call-attendant-status';
import { CardStatusResponse } from './commands/card-status';
import { SupportedMeterListResponse } from './commands/supported-meter-list';
import { SupportedEventListResponse } from './commands/supported-event-list';

interface ReceiveData {
  xml: string;
  class: string;
  type: string;
  sessionId: number;
  error: number;
  command?: {
    name: string;
    data: string;
  };
}

export class EmdiFactory {
  static createCommand(type: string): EmdiCommand {
    switch (EmdiRequests[type]) {
      case EmdiRequests.Heartbeat:
        return new HeartbeatRequest();

      case EmdiRequests.CommsOnLine:
        return new CommsOnLineRequest();

      case EmdiRequests.GetFunctionalGroups:
        return new GetFunctionalGroupsRequest();

      case EmdiRequests.ClearEventSub:
        return new ClearEventSubRequest();

      case EmdiRequests.ClearMeterSub:
        return new ClearMeterSubRequest();

      case EmdiRequests.ContentMessage:
        return new ContentMessageRequest();

      case EmdiRequests.ContentToHostMessage:
        return new ContentToHostMessageRequest();

      case EmdiRequests.GetActiveContent:
        return new GetActiveContentRequest();

      case EmdiRequests.GetCabinetStatus:
        return new GetCabinetStatusRequest();

      case EmdiRequests.GetCallAttendantState:
        return new GetCallAttendantStateRequest();

      case EmdiRequests.GetCardState:
        return new GetCardStateRequest();

      case EmdiRequests.GetDeviceVisibleState:
        return new GetDeviceVisibleStateRequest();

      case EmdiRequests.GetEventSubList:
        return new GetEventSubListRequest();

      case EmdiRequests.GetMeterInfo:
        return new GetMeterInfoRequest();

      case EmdiRequests.GetMeterSub:
        return new GetMeterSubCommand();

      case EmdiRequests.GetSupportedEventList:
        return new GetSupportedEventListRequest();

      case EmdiRequests.GetSupportedMeterList:
        return new GetSupportedMeterListRequest();

      case EmdiRequests.LogContentEvent:
        return new LogContentEventRequest();

      case EmdiRequests.SetCallAttendantState:
        return new SetCallAttendantStateRequest();

      case EmdiRequests.SetCardRemoved:
        return new SetCardRemovedRequest();

      case EmdiRequests.SetDeviceVisibleState:
        return new SetDeviceVisibleStateRequest();

      case EmdiRequests.SetEventSub:
        return new SetEventSubRequest();

      case EmdiRequests.SetMeterSub:
        return new SetMeterSubRequest();

      case EmdiRequests.GetEgmId:
        return new GetEgmIdRequest();

      default:
        console.error(`Request not found, ${type}`);
    }
  }

  static createResponseOrEvent(
    xml: string
  ): Promise<EmdiResponse | EmdiEvent | EmdiError> {
    return new Promise<EmdiResponse | EmdiEvent | EmdiError>(
      (resolve, reject) => {
        try {
          xml2js.parseString(xml, (error, json) => {
            if (error) {
              reject(error);
              return;
            }

            // this.logger.log('json data =', json);

            const cls = Object.keys(json['md:mdMsg'])[1];

            const data: ReceiveData = {
              xml: xml,
              class: Object.keys(json['md:mdMsg'])[1],
              type: json['md:mdMsg'][cls][0]['$']['md:cmdType'],
              sessionId: parseInt(
                json['md:mdMsg'][cls][0]['$']['md:sessionId'],
                10
              ),
              error: parseInt(json['md:mdMsg'][cls][0]['$']['md:errorCode'], 10)
            };

            data.class = data.class.substr(data.class.indexOf(':') + 3);

            // this.logger.log('receive data =', cls, data);

            const cmd = Object.keys(json['md:mdMsg'][cls][0])[1];

            if (cmd) {
              data.command = {
                name: cmd.substr(cmd.indexOf(':') + 1),
                data: json['md:mdMsg'][cls][0][cmd][0]
              };
            }

            if (data.error > 0) {
              resolve(new EmdiError(data.error, data.class as any));
            } else if (data.type === 'response') {
              const response = this.createResponse(data);
              response.sessionId = data.sessionId;
              response.xml = xml;
              resolve(response);
            } else if (data.type === 'request') {
              const event = this.createEvent(data);
              event.sessionId = data.sessionId;
              event.xml = xml;
              resolve(event);
            }
          });
        } catch (error) {
          reject(error);
        }
      }
    );
  }

  private static createEvent(data: ReceiveData): EmdiEvent {
    const type = this.toUpperCamelCase(data.command.name);
    // this.logger.log('event =', event);

    switch (EmdiEvents[type]) {
      case EmdiEvents.EventReport:
        return new EventReportEvent(data.command.data);

      case EmdiEvents.MeterReport:
        return new MeterReportEvent(data.command.data);

      case EmdiEvents.ContentMessage:
        return new ContentMessageEvent(data.command.data);

      case EmdiEvents.HostToContentMessage:
        return new HostToContentMessageEvent(data.command.data);

      default:
        throw new Error(`Event not found, ${type}`);
    }
  }

  private static createResponse(data: ReceiveData): EmdiResponse {
    const type = this.toUpperCamelCase(data.command.name);
    // this.logger.log('response =', response);

    switch (EmdiResponses[type]) {
      case EmdiResponses.HeartbeatAck:
        return new HeartbeatAckResponse();

      case EmdiResponses.CommsOnLineAck:
        return new CommsOnLineAckResponse(data.command.data);

      case EmdiResponses.FunctionalGroupList:
        return new FunctionalGroupListResponse(data.command.data);

      case EmdiResponses.EventSubList:
        return new EventSubListResponse(data.command.data);

      case EmdiResponses.MeterSubList:
        return new MeterSubListResponse(data.command.data);

      case EmdiResponses.ClearEventSubAck:
        return new ClearEventSubAckResponse();

      case EmdiResponses.ContentMessageAck:
        return new ContentMessageAckResponse(data.command.data);

      case EmdiResponses.DeviceVisibleStatus:
        return new DeviceVisibleStatusResponse(data.command.data);

      case EmdiResponses.CallAttendantStatus:
        return new CallAttendantStatusResponse(data.command.data);

      case EmdiResponses.CardStatus:
        return new CardStatusResponse(data.command.data);

      case EmdiResponses.ContentToHostMessageAck:
        return new ContentToHostMessageAckResponse();

      case EmdiResponses.CabinetStatus:
        return new CabinetStatusResponse(data.command.data);

      case EmdiResponses.SupportedMeterList:
        return new SupportedMeterListResponse(data.command.data);

      case EmdiResponses.ActiveContentList:
        return new ActiveContentListResponse(data.command.data);

      case EmdiResponses.SupportedEventList:
        return new SupportedEventListResponse(data.command.data);

      case EmdiResponses.LogContentEventAck:
        return new LogContentEventAckResponse();

      case EmdiResponses.MeterReport:
        return new MeterReportResponse(data.command.data);

      case EmdiResponses.ActiveContentList:
        return new ActiveContentListResponse(data.command.data);

      case EmdiResponses.EgmId:
        return new EgmIdResponse(data.command.data);

      default:
        throw new Error(`Response not found, ${type}`);
    }
  }

  private static toUpperCamelCase(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }
}
