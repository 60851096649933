import {
  EmdiEvent,
  EmdiEvents,
  EmdiClasses,
  EmdiResponses,
  EmdiResponse,
  MeterReportItem,
  MeterType
} from '../emdi-defs';
import { MeterReportAck } from './meter-report-ack';

export class MeterReportEvent implements EmdiEvent {
  name = 'MeterReport';
  eventType = EmdiEvents.MeterReport;
  class = EmdiClasses.Meters;
  sessionId: number;
  xml: string;
  ack = new MeterReportAck();
  items: MeterReportItem[] = [];

  constructor(data: string) {
    // console.log('MeterReportEvent =', data);

    if (data['md:meterInfo'] === undefined) {
      console.error(`MeterReportEvent: Invalid data`);
      return;
    }

    for (const item of data['md:meterInfo']) {
      const name: string = item['$']['md:meterName'];
      const type: MeterType = item['$']['md:meterType'];
      const value: number = +item['$']['md:meterValue'];

      this.items.push({
        name,
        type,
        value
      });
    }
  }
}

export class MeterReportResponse implements EmdiResponse {
  name = 'MeterReport';
  responseType = EmdiResponses.MeterReport;
  class = EmdiClasses.Meters;
  sessionId: number;
  xml: string;
  items: MeterReportItem[] = [];

  constructor(data: string) {
    // console.log('MeterReportResponse =', data);

    if (data['md:meterInfo'] === undefined) {
      console.error(`MeterReportResponse: Invalid data`);
      return;
    }

    for (const item of data['md:meterInfo']) {
      const name: string = item['$']['md:meterName'];
      const type: MeterType = item['$']['md:meterType'];
      const value: number = +item['$']['md:meterValue'];

      this.items.push({
        name,
        type,
        value
      });
    }
  }
}
