export const parseQuery = (queryString: string): any => {
  const query = {};
  const start = queryString.indexOf('?');
  const pairs = (start >= 0
    ? queryString.substr(start + 1)
    : queryString
  ).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
};
