import { EmdiClasses, EmdiRequests, EmdiRequest } from '../emdi-defs';

export class SetDeviceVisibleStateRequest implements EmdiRequest {
  name = 'SetDeviceVisibleState';
  requestType = EmdiRequests.SetDeviceVisibleState;
  class = EmdiClasses.Cabinet;
  sessionId = 0;
  deviceVisibleState = false;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdCabinet xmlns:md="http://mediaDisplay.igt.com" md:cmdType="request" md:sessionId="${this.sessionId}">
               <md:setDeviceVisibleState md:deviceVisibleState="${this.deviceVisibleState}" />
           </md:mdCabinet>
        </md:mdMsg>`;
  }
}
