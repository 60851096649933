import {
  EmdiResponse,
  EmdiResponses,
  EmdiClasses,
  MeterSubscription
} from '../emdi-defs';

export class MeterSubListResponse implements EmdiResponse {
  name = 'MeterSubList';
  responseType = EmdiResponses.MeterSubList;
  class = EmdiClasses.Meters;
  sessionId: number;
  xml: string;
  meterSubscriptions: MeterSubscription[] = [];

  constructor(data: string) {
    // console.log('MeterSubListResponse =', data);

    if (data['md:meterSubscription'] === undefined) {
      console.error(`MeterSubListResponse: Invalid data`);
      return;
    }

    for (const subs of data['md:meterSubscription']) {
      this.meterSubscriptions.push({
        name: subs['$']['md:meterName'],
        type: subs['$']['md:meterType']
      });
    }
  }
}
