import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class LoggerService {
  
  logName = '';
  isShowingLogName = true;
 
  setLogName(name: string) {
    this.logName = name;
  }

  setLogNameVisibility(visibility: 'show'|'hide') {
    if (visibility === 'show') {
      this.isShowingLogName = true;
    }
    else if (visibility === 'hide') {
      this.isShowingLogName = false;
    }
  }
  
  log(message: any) {
    const prefix = (this.isShowingLogName && this.logName) ? `${this.logName} | ` : '';
    console.log(`${prefix}${message}`);
  }
}
