import { EmdiResponse, EmdiResponses, EmdiClasses } from '../emdi-defs';

export class CallAttendantStatusResponse implements EmdiResponse {
  name = 'CallAttendantStatus';
  responseType = EmdiResponses.CallAttendantStatus;
  class = EmdiClasses.Cabinet;
  sessionId: number;
  xml: string;
  callAttendantActive = false;

  constructor(data: string) {
    // console.log('CallAttendantStatusResponse =', data);

    this.callAttendantActive = data['$']['md:callAttendantActive'] === 'true';
  }
}
