import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [Error404Component, Error500Component]
})
export class PagesModule {}
