import { EmdiClasses, EmdiRequest, EmdiRequests } from '../emdi-defs';

export class SetCallAttendantStateRequest implements EmdiRequest {
  name = 'SetCallAttendantState';
  requestType = EmdiRequests.SetCallAttendantState;
  class = EmdiClasses.Cabinet;
  sessionId = 0;
  enable = false;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdCabinet xmlns:md="http://mediaDisplay.igt.com" md:cmdType="request" md:sessionId="${this.sessionId}">
               <md:setCallAttendantState md:enable="${this.enable}" />
           </md:mdCabinet>
        </md:mdMsg>`;
  }
}
