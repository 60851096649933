import { EmdiRequests, EmdiClasses, EmdiRequest } from '../emdi-defs';

export class GetCabinetStatusRequest implements EmdiRequest {
  name = 'GetCabinetStatus';
  requestType = EmdiRequests.GetCabinetStatus;
  class = EmdiClasses.Cabinet;
  sessionId = 0;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com" xmlns:plc="http://www.gamingstandards.com/emdi/schemas/v1b/PLC">
           <md:mdCabinet md:cmdType="request" md:sessionId="${this.sessionId}">
               <plc:getCabinetStatus />
           </md:mdCabinet>
        </md:mdMsg>`;
  }
}
