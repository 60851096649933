import { EmdiResponses, EmdiClasses, EmdiResponse, ActiveContent } from '../emdi-defs';

export class ActiveContentListResponse implements EmdiResponse {
  name = 'ActiveContentList';
  responseType = EmdiResponses.ActiveContentList;
  class = EmdiClasses.ContentToContent;
  sessionId: number;
  xml: string;
  contentList: ActiveContent[] = [];

  constructor(data: string) {
    // console.log('ActiveContentListResponse =', data);

    if (data['cci:activeContent'] === undefined) {
      console.error(`ActiveContentListResponse: Invalid data`);
      return;
    }

    for (const content of data['cci:activeContent']) {
      this.contentList.push({
        contentId: +content['$']['cci:contentId'],
        displayId: +content['$']['cci:mediaDisplayId']
      });
    }
  }
}
