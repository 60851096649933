import {
  EmdiResponse,
  EmdiClasses,
  EmdiResponses,
  SupportedMeter
} from '../emdi-defs';

export class SupportedMeterListResponse implements EmdiResponse {
  name = 'SupportedMeterList';
  responseType = EmdiResponses.SupportedMeterList;
  class = EmdiClasses.Meters;
  sessionId: number;
  xml: string;
  supportedMeters: SupportedMeter[] = [];

  constructor(data: string) {
    // console.log('SupportedMeterListResponse =', data);

    for (const meter of data['md:supportedMeter']) {
      this.supportedMeters.push({
        name: meter['$']['md:meterName'],
        type: meter['$']['md:meterType']
      });
    }
  }
}
