import { EmdiResponse, EmdiResponses, EmdiClasses } from '../emdi-defs';

export class EventSubListResponse implements EmdiResponse {
  name = 'EventSubList';
  responseType = EmdiResponses.EventSubList;
  class = EmdiClasses.EventHandler;
  sessionId: number;
  xml: string;
  eventSubscriptions: { code: string }[] = [];

  constructor(data: string) {
    // console.log('EventSubListResponse =', data);

    if (data['md:eventSubscription'] === undefined) {
      console.error(`EventSubListResponse: Invalid data`);
      return;
    }

    for (const subs of data['md:eventSubscription']) {
      this.eventSubscriptions.push({
        code: subs['$']['md:eventCode']
      });
    }
  }
}
