import { EmdiEvent, EmdiEvents, EmdiClasses } from '../emdi-defs';
import { HostToContentMessageAck } from './host-to-content-message-ack';

export class HostToContentMessageEvent implements EmdiEvent {
  name = 'HostToContentMessage';
  eventType = EmdiEvents.HostToContentMessage;
  class = EmdiClasses.Cabinet;
  sessionId: number;
  xml: string;
  ack = new HostToContentMessageAck();
  instructions: string[] = [];

  constructor(data: string) {
    // console.log('HostToContentMessageEvent', data);

    for (const inststruction of data['hci:instructionData']) {
      const decoded = atob(inststruction);
      this.instructions.push(decoded);
    }
  }
}
