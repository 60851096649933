import { EmdiResponse, EmdiClasses, EmdiResponses } from '../emdi-defs';

export class CommsOnLineAckResponse implements EmdiResponse {
  name = 'CommsOnLineAck';
  responseType = EmdiResponses.CommsOnLineAck;
  class = EmdiClasses.Comms;
  sessionId: number;
  xml: string;
  sessionValid = false;

  constructor(data: string) {
    // console.log('CommsOnLineAck: data =', data);
    
    this.sessionValid = data['$']['md:sessionValid'] == 'true';
  }
}
