import {
  EmdiEvent,
  EmdiEvents,
  EmdiClasses,
  EventReportItem
} from '../emdi-defs';
import { EventAck } from './event-ack';
import moment from 'moment';

export class EventReportEvent implements EmdiEvent {
  name = 'EventReport';
  eventType = EmdiEvents.EventReport;
  class = EmdiClasses.EventHandler;
  ack = new EventAck();
  sessionId: number;
  xml: string;
  items: EventReportItem[] = [];

  constructor(data: string) {
    // console.log('EventReportEvent =', data);

    if (data['md:eventItem'] === undefined) {
      console.error(`EventReportEvent: Invalid data`);
      return;
    }

    for (const item of data['md:eventItem']) {
      const code: string = item['$']['md:eventCode'];

      switch (code) {
        case 'IGT_CBE100':
          this.items.push({ code, ...this.getEgmState(item) });
          break;

        case 'G2S_CBE101':
          this.items.push({ code, ...this.getLocale(item) });
          break;

        case 'IGT_IDE100':
        case 'IGT_IDE101':
        case 'IGT_IDE102':
          this.items.push({ code, ...this.getCardStatus(item) });
          break;

        case 'G2S_MDE100':
        case 'G2S_MDE101':
          this.items.push({ code, ...this.getActiveContent(item) });
          break;

        case 'G2S_GME101':
        case 'G2S_GME102':
        case 'G2S_GME103':
        case 'G2S_GME104':
        case 'G2S_GME105':
        case 'G2S_GME106':
        case 'G2S_GME107':
        case 'G2S_GME108':
        case 'G2S_GME109':
        case 'G2S_GME110':
        case 'G2S_GME111':
          this.items.push({ code, ...this.getRecallLog(item) });
          break;

        default:
          this.items.push({ code });
      }
    }
  }

  private getEgmState(item: any) {
    const egmState = item['md:cabinetStatus'][0]['$']['md:egmState'];

    return { egmState };
  }

  private getLocale(item: any) {
    let localeId = 'en_US';

    if (item['md:cabinetStatus'][0]['$']['plc:localeId']) {
      localeId = item['md:cabinetStatus'][0]['$']['plc:localeId'];
    }

    return { localeId };
  }

  private getCardStatus(item: any) {
    const cardIn = item['md:cardStatus'][0]['$']['md:cardIn'];
    const idReaderType = item['md:cardStatus'][0]['$']['md:idReaderType'];
    const idNumber = item['md:cardStatus'][0]['$']['md:idNumber'];
    const idValidExpired = item['md:cardStatus'][0]['$']['md:idValidExpired'];
    const idReaderId = item['md:cardStatus'][0]['$']['md:idReaderId'];

    return { cardIn, idReaderType, idNumber, idValidExpired, idReaderId };
  }

  private getActiveContent(item: any) {
    const mediaDisplayId =
      item['cci:activeContent'][0]['$']['cci:mediaDisplayId'];
    const contentId = item['cci:activeContent'][0]['$']['cci:contentId'];

    return { mediaDisplayId, contentId };
  }

  private getRecallLog(item: any) {
    const themeId = item['rcl:recallLog'][0]['$']['rcl:themeId'];
    const paytableId = item['rcl:recallLog'][0]['$']['rcl:paytableId'];
    const denomId = item['rcl:recallLog'][0]['$']['rcl:denomId'];
    const gameDateTime = moment(
      item['rcl:recallLog'][0]['$']['rcl:gameDateTime']
    ).toDate();
    const playResult = item['rcl:recallLog'][0]['$']['rcl:playResult'];
    const initialWager = +item['rcl:recallLog'][0]['$']['rcl:initialWager'];
    const finalWager = +item['rcl:recallLog'][0]['$']['rcl:finalWager'];
    const initialWin = +item['rcl:recallLog'][0]['$']['rcl:initialWin'];
    const secondaryPlayed = +item['rcl:recallLog'][0]['$'][
      'rcl:secondaryPlayed'
    ];
    const secondaryWager = +item['rcl:recallLog'][0]['$']['rcl:secondaryWager'];
    const secondaryWin = +item['rcl:recallLog'][0]['$']['rcl:secondaryWin'];
    const finalWin = +item['rcl:recallLog'][0]['$']['rcl:finalWin'];

    return {
      themeId,
      paytableId,
      denomId,
      gameDateTime,
      playResult,
      initialWager,
      finalWager,
      initialWin,
      secondaryPlayed,
      secondaryWager,
      secondaryWin,
      finalWin
    };
  }
}
