import {
  EmdiClasses,
  EmdiEvent,
  EmdiEvents,
  EmdiRequest,
  EmdiRequests
} from '../emdi-defs';
import { ContentMessageAck } from './content-message-ack';

export class ContentMessageRequest implements EmdiRequest {
  name = 'ContentMessage';
  requestType = EmdiRequests.ContentMessage;
  class = EmdiClasses.ContentToContent;
  sessionId = 0;
  mediaDisplayId: number;
  contentId: number;
  contentData: string;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <cci:mdContentToContent xmlns:md="http://mediaDisplay.igt.com" xmlns:cci="http://www.gamingstandards.com/emdi/schemas/v1b/CCI" md:cmdType="request" md:sessionId="${
             this.sessionId
           }">
                <cci:contentMessage cci:mediaDisplayId="${
                  this.mediaDisplayId
                }" cci:contentId="${this.contentId}">
                    <cci:contentData>${btoa(this.contentData)}</cci:contentData>
                </cci:contentMessage>
           </cci:mdContentToContent>
        </md:mdMsg>`;
  }
}

export class ContentMessageEvent implements EmdiEvent {
  name = 'ContentMessage';
  eventType = EmdiEvents.ContentMessage;
  class = EmdiClasses.ContentToContent;
  sessionId: number;
  xml: string;
  ack = new ContentMessageAck();
  mediaDisplayId: number;
  contentId: number;
  instructions: string[] = [];

  constructor(data: string) {
    // console.log('ContentMessageEvent =', data);

    this.mediaDisplayId = +data['cci:mediaDisplayId'];
    this.contentId = +data['cci:contentId'];

    for (const instruction of data['cci:contentData']) {
      const decoded = atob(instruction);
      this.instructions.push(decoded);
    }
  }
}
