import { EmdiClasses, EmdiRequest, EmdiRequests } from '../emdi-defs';

export class GetEventSubListRequest implements EmdiRequest {
  name = 'GetEventSubList';
  requestType = EmdiRequests.GetEventSubList;
  class = EmdiClasses.EventHandler;
  sessionId = 0;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdEventHandler xmlns:md="http://mediaDisplay.igt.com" md:cmdType="request" md:sessionId="${this.sessionId}">
               <md:getEventSubList />
           </md:mdEventHandler>
        </md:mdMsg>`;
  }
}
