import { EmdiClasses, EmdiRequest, EmdiRequests } from '../emdi-defs';

export class GetCallAttendantStateRequest implements EmdiRequest {
    name = 'GetCallAttendantState';
    requestType = EmdiRequests.GetCallAttendantState;
    class = EmdiClasses.Cabinet;
    sessionId = 0;

    xml(): string {
        return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdCabinet xmlns:md="http://mediaDisplay.igt.com" md:cmdType="request" md:sessionId="${this.sessionId}">
               <md:getCallAttendantState />
           </md:mdCabinet>
        </md:mdMsg>`;
    }
}
