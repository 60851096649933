import { EmdiClasses, EmdiRequest, EmdiRequests } from '../emdi-defs';

export class GetCardStateRequest implements EmdiRequest {
  name = 'GetCardState';
  requestType = EmdiRequests.GetCardState;
  class = EmdiClasses.Cabinet;
  sessionId = 0;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
            <md:mdCabinet xmlns:md="http://mediaDisplay.igt.com" md:cmdType="request" md:sessionId="${this.sessionId}">
                <md:getCardState />
            </md:mdCabinet>
        </md:mdMsg>`;
  }
}
