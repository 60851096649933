import { EmdiAck, EmdiClasses, EmdiAcks } from '../emdi-defs';

export class EventAck implements EmdiAck {
    name = 'ContentMessageAck';
    ackType = EmdiAcks.EventAck;
    class = EmdiClasses.EventHandler;
    sessionId = 0;

    xml(): string {
        return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdEventHandler md:cmdType="response" md:sessionId="${this.sessionId}">
               <md:eventAck />
           </md:mdEventHandler>
        </md:mdMsg>`;
    }
}
