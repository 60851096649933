import {
  EmdiClasses,
  MeterSubscription,
  EmdiRequests,
  EmdiRequest
} from '../emdi-defs';

export class ClearMeterSubRequest implements EmdiRequest {
  name = 'ClearMeterSub';
  requestType = EmdiRequests.ClearMeterSub;
  class = EmdiClasses.Meters;
  sessionId = 0;
  meterSubscriptions: MeterSubscription[] = [];

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdMeters xmlns:md="http://mediaDisplay.igt.com" md:cmdType="request" md:sessionId="${
             this.sessionId
           }">
                <md:clearMeterSub>
                    ${this.getMeterSubs()}
                </md:clearMeterSub>
           </md:mdMeters>' +
        </md:mdMsg>`;
  }

  private getMeterSubs(): string {
    let xml = '';

    for (const subs of this.meterSubscriptions) {
      xml += `<md:meterSubscription md:meterName="${subs.name}"`;

      if (subs.type) {
        xml += ` md:meterType="${subs.type}"`;
      }

      xml += ` />`;
    }

    return xml;
  }
}
