import {
  EmdiCommand,
  EmdiResponse,
  EmdiEvent,
  EmdiError,
  SupportedEvent,
  MeterSubscription,
  EmdiConstants,
  SupportedMeter,
  MeterReportItem,
  EmdiEvents,
  EmdiAck,
  EmdiRequest,
  EmdiRequests,
  EmdiResponses,
  EmdiClasses,
  ActiveContent
} from './emdi-defs';
import { EmdiFactory } from './emdi-factory';
import { CommsOnLineRequest } from './commands/comms-on-line';
import { CommsOnLineAckResponse } from './commands/comms-on-line-ack';

import { Subject, Subscription, BehaviorSubject, throwError } from 'rxjs';
import {
  map,
  timeout,
  catchError,
  filter,
  mergeMap,
  take,
  takeUntil,
  skip,
  first,
  takeWhile,
  tap
} from 'rxjs/operators';
import _ from 'lodash';
import utf8 from 'utf8';

import { SetDeviceVisibleStateRequest } from './commands/set-device-visible-state';
import {
  ContentMessageRequest,
  ContentMessageEvent
} from './commands/content-message';
import { Injectable } from '@angular/core';
import {
  GetEgmIdRequest,
  EgmIdResponse,
  DeviceVisibleStatusResponse,
  SetEventSubRequest,
  EventSubListResponse,
  ClearEventSubRequest,
  GetEventSubListRequest,
  GetSupportedEventListRequest,
  SupportedEventListResponse,
  ClearMeterSubRequest,
  SetMeterSubRequest,
  GetSupportedMeterListRequest,
  SupportedMeterListResponse,
  MeterSubListResponse,
  GetMeterSubCommand,
  GetMeterInfoRequest,
  MeterReportResponse,
  EventReportEvent,
  MeterReportEvent,
  HostToContentMessageEvent,
  GetDeviceVisibleStateRequest,
  GetActiveContentRequest,
  ActiveContentListResponse,
  HeartbeatRequest
} from './commands';
import { formatXml } from './format-xml';
import { SocketService } from './socket.service';
import { LoggerService } from '../services/logger.service';

const BASE_PORT = 1023;

@Injectable({
  providedIn: 'root'
})
export class EmdiClientService {
  private sessionId = 1;
  private pulseInterval;
  private heartbeat = new HeartbeatRequest();
  private messages: Subject<string>;

  private subscription: Subscription;
  private _connected = new BehaviorSubject<boolean>(false);
  private _validated = new BehaviorSubject<boolean>(false);
  private _event = new Subject<EmdiEvent>();
  private _response = new Subject<EmdiResponse>();
  private _request = new Subject<EmdiRequest>();
  private _ack = new Subject<EmdiAck>();
  private _error = new Subject<EmdiError>();

  private deviceId: number;
  private accessToken: number;
  private address: string;
  private url: string;

  egmId = '';
  activeContent: ActiveContent[] = [];

  constructor(private socket: SocketService, private logger: LoggerService) {
    this.socket.opened.subscribe(connected =>
      connected ? this.onConnected() : this.onDisconnected()
    );
  }

  get connected() {
    return this._connected.asObservable();
  }

  get disconnected() {
    return this._connected.asObservable().pipe(filter(connected => !connected));
  }

  get validated() {
    return this._validated.asObservable();
  }

  get eventReceived() {
    return this._event.asObservable();
  }

  get eventReports() {
    return this._event.asObservable().pipe(
      filter(event => event.eventType === EmdiEvents.EventReport),
      map(event => <EventReportEvent>event),
      mergeMap(event => event.items)
    );
  }

  get meterReports() {
    return this._event.asObservable().pipe(
      filter(event => event.eventType === EmdiEvents.MeterReport),
      map(event => <MeterReportEvent>event),
      mergeMap(event => event.items)
    );
  }

  get contentMessages() {
    return this._event.asObservable().pipe(
      filter(event => event.eventType === EmdiEvents.ContentMessage),
      map(event => <ContentMessageEvent>event),
      mergeMap(event => event.instructions)
    );
  }

  get hostMessages() {
    return this._event.asObservable().pipe(
      filter(event => event.eventType === EmdiEvents.HostToContentMessage),
      map(event => <HostToContentMessageEvent>event),
      mergeMap(event => event.instructions)
    );
  }

  get responseReceived() {
    return this._response
      .asObservable()
      .pipe(
        filter(response => response.responseType !== EmdiResponses.HeartbeatAck)
      );
  }

  get heartbeatAcknowledged() {
    return this._response
      .asObservable()
      .pipe(
        filter(response => response.responseType === EmdiResponses.HeartbeatAck)
      );
  }

  get requestSent() {
    return this._request
      .asObservable()
      .pipe(filter(request => request.requestType !== EmdiRequests.Heartbeat));
  }

  get heartbeatSent() {
    return this._request
      .asObservable()
      .pipe(filter(request => request.requestType === EmdiRequests.Heartbeat));
  }

  get ackSent() {
    return this._ack.asObservable();
  }

  get errorReported() {
    return this._error.asObservable();
  }

  connect(
    deviceId: number,
    accessToken: number,
    address = '127.0.0.1'
  ): Promise<boolean> {
    if (this.url) {
      return Promise.resolve(true);
    }

    const port = BASE_PORT + deviceId;

    this.deviceId = deviceId;
    this.accessToken = accessToken;
    this.address = address;
    this.url = `ws://${address}:${port}`;

    return new Promise<boolean>(resolve => {
      try {
        // WARN: internally creates a new socket
        this.messages = this.socket.connect(this.url);

        this.subscription = this.messages.subscribe(
          data => {
            this.receive(data);
          },
          err => {
            this.onError(new EmdiError(err, EmdiClasses.None));
          }
        );

        resolve(true);
      } catch (err) {
        resolve(false);
      }
    });
  }

  validate(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      try {
        if (this._validated.value) {
          resolve(true);
          return;
        }

        const command = new CommsOnLineRequest();
        command.accessToken = parseInt(this.accessToken.toString(), 10);

        this._sendCommand(command)
          .then(response => {
            const valid = (<CommsOnLineAckResponse>response).sessionValid;

            if (valid) {
              this.onValidated();
            }

            resolve(valid);
          })
          .catch(error => {
            throw error;
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  disconnect(code: number, reason = ''): void {
    this.socket.disconnect(this.url, code, reason);
  }

  show(): Promise<boolean> {
    return this.setDeviceVisbleState(true);
  }

  hide(): Promise<boolean> {
    return this.setDeviceVisbleState(false);
  }

  async getEgmId(): Promise<string> {
    const command = new GetEgmIdRequest();

    const response = await this._sendCommand(command);

    return (<EgmIdResponse>response).egmId;
  }

  async getActiveContent(): Promise<ActiveContent[]> {
    const command = new GetActiveContentRequest();

    const response = await this._sendCommand(command);

    return (<ActiveContentListResponse>response).contentList;
  }

  async sendContent(
    mediaDisplayId: number,
    contentId: number,
    contentData: string
  ): Promise<void> {
    const command = new ContentMessageRequest();
    command.mediaDisplayId = mediaDisplayId;
    command.contentId = contentId;
    command.contentData = contentData;

    await this.sendCommand(command);
  }

  async clearEvents(...codes: string[]): Promise<void> {
    const command = new ClearEventSubRequest();

    codes.forEach(code => command.eventSubscriptions.push({ code: code }));

    await this.sendCommand(command);
  }

  async clearAllEvents(): Promise<void> {
    const command = new ClearEventSubRequest();

    command.eventSubscriptions = [{ code: 'IGT_all' }];

    await this.sendCommand(command);
  }

  async subscribeToEvents(...codes: string[]): Promise<string[]> {
    const command = new SetEventSubRequest();

    codes.forEach(code => command.eventSubscriptions.push({ code: code }));

    const response = await this.sendCommand(command);

    const subs = (<EventSubListResponse>response).eventSubscriptions;

    return subs.map(sub => sub.code);
  }

  async getSupportedEvents(): Promise<SupportedEvent[]> {
    const command = new GetSupportedEventListRequest();

    const response = await this.sendCommand(command);

    return (<SupportedEventListResponse>response).supportedEvents;
  }

  async getEvents(): Promise<string[]> {
    const command = new GetEventSubListRequest();

    const response = await this.sendCommand(command);

    const subs = (<EventSubListResponse>response).eventSubscriptions;

    return subs.map(sub => sub.code);
  }

  async clearMeters(...meters: MeterSubscription[]): Promise<void> {
    const command = new ClearMeterSubRequest();

    meters.forEach(meter => command.meterSubscriptions.push(meter));

    await this.sendCommand(command);
  }

  async clearAllMeters(): Promise<void> {
    const command = new ClearMeterSubRequest();

    command.meterSubscriptions = [{ name: EmdiConstants.all, type: undefined }];

    await this.sendCommand(command);
  }

  async subscribeToMeters(...meters: MeterSubscription[]): Promise<string[]> {
    const command = new SetMeterSubRequest();

    meters.forEach(meter => command.meterSubscriptions.push(meter));

    const response = await this.sendCommand(command);

    const subs = (<MeterSubListResponse>response).meterSubscriptions;

    return subs.map(sub => sub.name);
  }

  async getSupportedMeters(): Promise<SupportedMeter[]> {
    const command = new GetSupportedMeterListRequest();

    const response = await this.sendCommand(command);

    return (<SupportedMeterListResponse>response).supportedMeters;
  }

  async getMeters(): Promise<MeterSubscription[]> {
    const command = new GetMeterSubCommand();

    const response = await this.sendCommand(command);

    return (<MeterSubListResponse>response).meterSubscriptions;
  }

  async getMeterReport(
    ...meters: MeterSubscription[]
  ): Promise<MeterReportItem[]> {
    const command = new GetMeterInfoRequest();

    meters.forEach(meter => command.meterSubscriptions.push(meter));

    const response = await this.sendCommand(command);

    return (<MeterReportResponse>response).items;
  }

  private onEventReceived(event: EmdiEvent) {
    // this.track(new Date(), event);
    this._event.next(event);
  }

  private onResponseReceived(response: EmdiResponse) {
    this._response.next(response);
  }

  private onRequestSent(request: EmdiRequest) {
    this._request.next(request);
  }

  private onAckSent(ack: EmdiAck) {
    this._ack.next(ack);
  }

  private onError(error: EmdiError) {
    this._error.next(error);
  }

  private onConnected() {
    this.logger.log(`connected to device (${this.deviceId})`);

    this.sessionId = 1;

    this._connected.next(true);

    this.validate();
  }

  private onDisconnected() {
    this.logger.log(`disconnected from device (${this.deviceId})`);

    clearInterval(this.pulseInterval);
    this._connected.next(false);
    this._validated.next(false);
  }

  private async onValidated() {
    this.egmId = await this.getEgmId();

    this.activeContent = await this.getActiveContent();

    this._validated.next(true);
  }

  private async sendCommand(command: EmdiCommand): Promise<EmdiResponse> {
    const shouldTrack =
      command.name !== 'ContentMessage' ||
      (<any>command).mediaDisplayId !== EmdiConstants.testerDeviceId;

    const response = await this._sendCommand(command);

    if (shouldTrack) {
      // await this.track(sendTime, command);
      // await this.track(responseTime, response);
    }

    return response;
  }

  private _sendCommand(command: EmdiCommand): Promise<EmdiResponse> {
    return new Promise<EmdiResponse>((resolve, reject) => {
      try {
        if (!this._connected.value) {
          throw new Error(`No connection sending ${command.name}`);
        }

        if (this.pulseInterval) {
          clearInterval(this.pulseInterval);
        }

        this.pulseInterval = setInterval(
          () =>
            this.pulse().then(undefined, err =>
              this.logger.log(`pulse failed: ${err}`)
            ),
          25000
        );

        const sessionId = this.sessionId;
        command.sessionId = sessionId;

        let xml = command.xml();

        xml = utf8.encode(xml);

        this.logger.log(`send = ${formatXml(xml)}`);

        this.messages.next(xml);

        if (this.isRequestType(command)) {
          this.onRequestSent(<EmdiRequest>command);
        } else if (this.isAckType(command)) {
          this.onAckSent(<EmdiAck>command);
        }

        this.sessionId++;

        this._response
          .asObservable()
          .pipe(
            takeUntil(this.disconnected),
            first(response => response.sessionId === command.sessionId),
            timeout(30000),
            catchError(err => throwError(`Response timeout: ${command.name}`))
          )
          .subscribe(
            result => resolve(result),
            err => {
              // debugger;
              console.error(`send error: ${JSON.stringify(err)}`);
              this.onError(new EmdiError(err, command.class));
              this.disconnect(4000, err.message);
              reject(err);
            },
            () => resolve()
          );
      } catch (error) {
        reject(error);
      }
    });
  }

  async getDeviceVisibleState(): Promise<boolean> {
    const command = new GetDeviceVisibleStateRequest();

    const response = await this.sendCommand(command);

    return (<DeviceVisibleStatusResponse>response).deviceVisibleState;
  }

  async setDeviceVisbleState(state: boolean): Promise<boolean> {
    const command = new SetDeviceVisibleStateRequest();

    command.deviceVisibleState = state;

    const response = await this.sendCommand(command);

    return (<DeviceVisibleStatusResponse>response).deviceVisibleState === state;
  }

  private sendResponse(event: EmdiEvent) {
    if (!this._connected.value) {
      throw new Error('No connection');
    }

    event.ack.sessionId = event.sessionId;
    let xml = event.ack.xml();

    xml = utf8.encode(xml);

    this.messages.next(xml);
  }

  private async pulse() {
    await this.sendCommand(this.heartbeat);
  }

  private async receive(xml: string) {
    this.logger.log(`receive = ${formatXml(xml)}`);

    const result = await EmdiFactory.createResponseOrEvent(xml);

    if (this.isErrorType(result)) {
      const error = <EmdiError>result;

      this.onError(error);
      this.disconnect(4000, JSON.stringify(error));
    } else if (this.isResponseType(result)) {
      const response = <EmdiResponse>result;

      this.onResponseReceived(response);
    } else if (this.isEventType(result)) {
      const event = <EmdiEvent>result;

      this.sendResponse(event);
      this.onEventReceived(event);
    }
  }

  private isErrorType(value: Object): value is EmdiError {
    return (<EmdiError>value).error !== undefined;
  }

  private isAckType(value: Object): value is EmdiAck {
    return !!value && (<EmdiAck>value).ackType !== undefined;
  }

  private isRequestType(value: Object): value is EmdiRequest {
    return !!value && (<EmdiRequest>value).requestType !== undefined;
  }

  private isResponseType(value: Object): value is EmdiResponse {
    return (<EmdiResponse>value).responseType !== undefined;
  }

  private isEventType(value: Object): value is EmdiEvent {
    return (<EmdiEvent>value).eventType !== undefined;
  }
}
