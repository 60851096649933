import { EmdiResponse, EmdiResponses, EmdiClasses } from '../emdi-defs';

export class FunctionalGroupListResponse implements EmdiResponse {
  name = 'FunctionalGroupList';
  responseType = EmdiResponses.FunctionalGroupList;
  class = EmdiClasses.Comms;
  sessionId: number;
  xml: string;
  groups: { name: string; commands: { name: string }[] }[] = [];

  constructor(data: string) {
    // console.log('FunctionalGroupList =', data);

    for (const group of data['md:functionalGroup']) {
      const groupItem = {
        name: group['$']['md:groupName'],
        commands: []
      };

      this.groups.push(groupItem);

      if (group['md:commandItem'] === undefined) {
        continue;
      }

      for (const command of group['md:commandItem']) {
        groupItem.commands.push({
          name: command['$']['md:commandName']
        });
      }
    }
  }
}
