import { EmdiResponse, EmdiClasses, EmdiResponses } from '../emdi-defs';

export class DeviceVisibleStatusResponse implements EmdiResponse {
  name = 'DeviceVisibleStatus';
  responseType = EmdiResponses.DeviceVisibleStatus;
  class = EmdiClasses.Cabinet;
  sessionId: number;
  xml: string;
  deviceVisibleState = false;

  constructor(data: string) {
    // console.log('DeviceVisibleStatusResponse =', data);

    if (data['$']) {
      this.deviceVisibleState = data['$']['md:deviceVisibleState'] !== 'false'; // Defaults to true
    } else {
      this.deviceVisibleState = true;
    }
  }
}
