import { EmdiResponse, EmdiResponses, EmdiClasses } from '../emdi-defs';

export class CabinetStatusResponse implements EmdiResponse {
  name = 'CabinetStatus';
  responseType = EmdiResponses.CabinetStatus;
  class = EmdiClasses.Cabinet;
  sessionId: number;
  xml: string;
  egmState: string;
  deviceClass: string;

  constructor(data: string) {
    console.log('CabinetStatusResponse =', data);

    this.egmState = data['$']['md:egmState'];
    this.deviceClass = data['$']['md:deviceClass'];
  }
}
