import { EmdiResponse, EmdiResponses, EmdiClasses, EmdiAck, EmdiAcks } from '../emdi-defs';

export class ContentMessageAckResponse implements EmdiResponse {
    name = 'ContentMessageAck';
    responseType = EmdiResponses.ContentMessageAck;
    class = EmdiClasses.ContentToContent;
    sessionId: number;
    xml: string;

    constructor(data: string) {
        // console.log('ContentMessageAckResponse: data =', data);
    }
}

export class ContentMessageAck implements EmdiAck {
    name = 'ContentMessageAck';
    ackType = EmdiAcks.ContentMessageAck;
    sessionId = 0;
    class = EmdiClasses.ContentToContent;

    xml(): string {
        return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <cci:mdContentToContent xmlns:cci="http://www.gamingstandards.com/emdi/schemas/v1b/CCI" md:cmdType="response" md:sessionId="${this.sessionId}">
               <cci:contentMessageAck />
           </cci:mdContentToContent>
        </md:mdMsg>`;
    }
}
