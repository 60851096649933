import { EmdiClasses, EmdiRequest, EmdiRequests } from '../emdi-defs';

export class GetActiveContentRequest implements EmdiRequest {
  name = 'GetActiveContent';
  requestType = EmdiRequests.GetActiveContent;
  class = EmdiClasses.ContentToContent;
  sessionId = 0;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <cci:mdContentToContent xmlns:cci="http://www.gamingstandards.com/emdi/schemas/v1b/CCI" md:cmdType="request" md:sessionId="${this.sessionId}">
               <cci:getActiveContent />
           </cci:mdContentToContent>
        </md:mdMsg>`;
  }
}
