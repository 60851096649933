import {
  EmdiResponse,
  EmdiClasses,
  EmdiResponses,
  SupportedEvent
} from '../emdi-defs';

export class SupportedEventListResponse implements EmdiResponse {
  name = 'SupportedEventList';
  responseType = EmdiResponses.SupportedEventList;
  class = EmdiClasses.EventHandler;
  sessionId: number;
  xml: string;
  supportedEvents: SupportedEvent[] = [];

  constructor(data: string) {
    // console.log('SupportedEventListResponse =', data);

    for (const event of data['md:supportedEvent']) {
      this.supportedEvents.push({
        code: event['$']['md:eventCode'],
        text: event['$']['md:eventText']
      });
    }
  }
}
