import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from './material/material.module';

import { EmdiModule } from './emdi';
import { ApiModule } from './api/api.module';

import { SidebarModule } from 'ng-sidebar';

import { DataTablesModule } from 'angular-datatables';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { jqxSplitterModule } from 'jqwidgets-ng/jqxsplitter';

import { ToastContainerComponent } from './compnents/toast-container/toast-container.component';

@NgModule({
  declarations: [ToastContainerComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    EmdiModule,
    MaterialModule,
    ApiModule,
    SidebarModule,
    DataTablesModule,
    NgbModule,
    jqxSplitterModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    EmdiModule,
    MaterialModule,
    ApiModule,
    SidebarModule,
    DataTablesModule,
    NgbModule,
    ToastContainerComponent,
    jqxSplitterModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: []
    };
  }
}
