import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { Error404Component } from './pages/error404/error404.component';
import { Error500Component } from './pages/error500/error500.component';

export const routes: Route[] = [
  { path: '', loadChildren: () => import('./content/content.module').then(m => m.ContentModule) },
  { path: 'test', loadChildren: () => import('./test/test.module').then(m => m.TestModule) },
  { path: '404', component: Error404Component },
  { path: '500', component: Error500Component },

  // Not found
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  declarations: [],
  exports: [RouterModule]
})
export class RoutesModule {
  constructor() {}
}
