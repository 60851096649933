import { EmdiClasses, EmdiRequest, EmdiRequests } from '../emdi-defs';

export class GetSupportedMeterListRequest implements EmdiRequest {
  name = 'GetSupportedMeterList';
  requestType = EmdiRequests.GetSupportedMeterList;
  class = EmdiClasses.Meters;
  sessionId = 0;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdMeters xmlns:md="http://mediaDisplay.igt.com" md:cmdType="request" md:sessionId="${this.sessionId}">
               <md:getSupportedMeterList />
           </md:mdMeters>
        </md:mdMsg>`;
  }
}
