import { EmdiClasses, EmdiRequest, EmdiRequests } from '../emdi-defs';

export class GetFunctionalGroupsRequest implements EmdiRequest {
  name = 'GetFunctionalGroups';
  requestType = EmdiRequests.GetFunctionalGroups;
  class = EmdiClasses.Comms;
  sessionId = 0;
  includeCommands = false;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdComms xmlns:md="http://mediaDisplay.igt.com" md:cmdType="request" md:sessionId="${this.sessionId}">
               <md:getFunctionalGroups md:includeCommands="${this.includeCommands}" />
           </md:mdComms>
        </md:mdMsg>`;
  }
}
