import { EmdiClasses, EmdiRequest, EmdiRequests } from '../emdi-defs';

export class CommsOnLineRequest implements EmdiRequest {
  name = 'CommsOnLine';
  requestType = EmdiRequests.CommsOnLine;
  class = EmdiClasses.Comms;
  sessionId = 0;
  requiresInput = true;
  accessToken: number;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdComms xmlns:md="http://mediaDisplay.igt.com" md:cmdType="request" md:sessionId="${this.sessionId}">
               <md:commsOnLine md:mdAccessToken="${this.accessToken}" />
           </md:mdComms>
        </md:mdMsg>`;
  }
}
