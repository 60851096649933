import {
  EmdiClasses,
  EventSubscription,
  EmdiRequest,
  EmdiRequests
} from '../emdi-defs';

export class ClearEventSubRequest implements EmdiRequest {
  name = 'ClearEventSub';
  requestType = EmdiRequests.ClearEventSub;
  class = EmdiClasses.EventHandler;
  sessionId = 0;
  eventSubscriptions: EventSubscription[] = [];

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdEventHandler xmlns:md="http://mediaDisplay.igt.com" md:cmdType="request" md:sessionId="${
             this.sessionId
           }">
                <md:clearEventSub>
                    ${this.getEventSubs()}
                </md:clearEventSub>
           </md:mdEventHandler>
        </md:mdMsg>`;
  }

  private getEventSubs(): string {
    let xml = '';

    for (const subs of this.eventSubscriptions) {
      xml += `<md:eventSubscription md:eventCode="${subs.code}" />`;
    }

    return xml;
  }
}
