import { EmdiClasses, EmdiAck, EmdiAcks } from '../emdi-defs';

export class HostToContentMessageAck implements EmdiAck {
  name = 'HostToContentMessageAck';
  ackType = EmdiAcks.HostToContentMessageAck;
  class = EmdiClasses.Cabinet;
  sessionId = 0;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdCabinet md:cmdType="response" md:sessionId="${this.sessionId}">
               <hci:hostToContentMessageAck xmlns:hci="http://www.gamingstandards.com/emdi/schemas/v1b/HCI" />
           </md:mdCabinet>
        </md:mdMsg>`;
  }
}
