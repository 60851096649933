import { EmdiClasses, EmdiRequest, EmdiRequests } from '../emdi-defs';

export class GetEgmIdRequest implements EmdiRequest {
  name = 'GetEgmId';
  requestType = EmdiRequests.GetEgmId;
  class = EmdiClasses.Host;
  sessionId = 0;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <hst:mdHost xmlns:hst="http://www.aristocrat.com/emdi/schemas/v1b/HST" md:cmdType="request" md:sessionId="${this.sessionId}">
               <hst:getEgmId />
           </hst:mdHost>
        </md:mdMsg>`;
  }
}
