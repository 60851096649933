import { EmdiClasses, EmdiAck, EmdiAcks } from '../emdi-defs';

export class MeterReportAck implements EmdiAck {
  name = 'MeterReportAck';
  ackType = EmdiAcks.MeterReportAck;
  class = EmdiClasses.Meters;
  sessionId = 0;

  xml(): string {
    return `<?xml version="1.0" encoding="UTF-8"?>
        <md:mdMsg xmlns:md="http://mediaDisplay.igt.com">
           <md:mdMeters md:cmdType="response" md:sessionId="${this.sessionId}">
               <md:meterReportAck />
           </md:mdMeters>
        </md:mdMsg>`;
  }
}
